import React, { useState } from "react";
import styled from "styled-components";
import getDomain from "../utils/get-domain";

const Root = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const Button = styled.div`
  user-select: none;
  font-family: "Openserif book";
  display: inline-block;
  cursor: pointer;
  :hover {
    opacity: 0.8;
    text-decoration: underline !important;
  }
`;

const DetailsStage = styled.div`
  font-family: "Openserif book";
  padding-top: 10px;
`;
const DetailsHeadline = styled.div`
  font-family: "Openserif", sans-serif;
  padding: 10px 0;
  color: #034860;
`;
const DetailBlock = styled.div`
  font-family: "Openserif book";
  line-height: 18px;
  a {
    color: #034860;
    text-decoration: underline !important;
  }
  white-space: nowrap;
`;
const Website = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  position: relative;
  top: 3px;
  max-width: 65%;
  line-height: 18px;
`;
const DetailBlockTitle = styled.span`
  font-family: "Openserif";
  width: 30%;
  display: inline-block;
  line-height: 18px;
`;

interface ResultRowDetailsProps {
  onOpen: () => void;
  data?: google.maps.places.PlaceResult;
}

const ResultRowDetails = ({ onOpen, data }: ResultRowDetailsProps) => {
  const [isOpen, setOpen] = useState(false);
  const weekdays = data?.opening_hours?.weekday_text || [];
  return (
    <Root>
      <Button
        onClick={() => {
          onOpen();
          setOpen(!isOpen);
        }}
      >
        {isOpen ? "▾" : "▸"} Weitere informationen
      </Button>
      {isOpen ? (
        <DetailsStage>
          {weekdays.length > 0 ? <DetailsHeadline>Öffnungszeiten</DetailsHeadline> : null}
          {weekdays.map((time) => (
            <DetailBlock key={time}>{time}</DetailBlock>
          ))}
          <DetailsHeadline>Kontakt</DetailsHeadline>
          <DetailBlock>
            <DetailBlockTitle>Telefon:</DetailBlockTitle>
            {data?.formatted_phone_number}
          </DetailBlock>
          <DetailBlock>
            <DetailBlockTitle>Webseite:</DetailBlockTitle>
            <Website href={data?.website} rel='noreferrer' target='_blank'>
              {getDomain(data?.website)}
            </Website>
          </DetailBlock>
        </DetailsStage>
      ) : null}
    </Root>
  );
};

export default ResultRowDetails;
