import React, { useState } from "react";
import styled from "styled-components";
import { ASSETS_PATH } from "../config";
import ResultRowDetails from "./result-row-details";

const Root: any = styled.div`
  box-sizing: border-box;
  font-family: "Openserif", sans-serif;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 110px;
`;
const Window: any = styled.div`
  box-sizing: border-box;
  padding: ${({ fullWidth }: any) => (fullWidth ? "5px" : "15px")};
  background: #fff;
  border-radius: 6px;
  min-height: 140px;
  font-weight: bold;
  font-size: 14px;
  height: 100%;
`;
const Address = styled.div`
  font-weight: normal;
  font-family: "Openserif book";
`;
const Open = styled.div`
  font-weight: normal;
  color: #47af61;
  padding: 10px 0;
  font-size: 14px;
  font-family: "Openserif book";
`;

const Content = styled.div`
  padding-right: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Route = styled.img`
  width: 28px;
  height: 28px;
`;

interface ResultRowProps {
  title: string;
  address: string;
  isOpen: boolean;
  fullWidth?: boolean;
  isActive?: boolean;
  placeId: string;
  map?: google.maps.Map;
}

const getRoute = (address: string) =>
  `https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=${address}`;

const ResultRow = ({
  title,
  address,
  isOpen,
  fullWidth = false,
  isActive,
  placeId,
  map,
}: ResultRowProps) => {
  const [details, setDetails] = useState<google.maps.places.PlaceResult>();
  const loadMore = () => {
    if (map) {
      const service = new google.maps.places.PlacesService(map);
      service.getDetails({ placeId }, (data: google.maps.places.PlaceResult) => {
        setDetails(data);
      });
    }
  };
  return (
    <Root fullWidth={fullWidth}>
      <Window fullWidth={fullWidth}>
        <Flex>
          <Content>
            <div>
              {title}
              <Address>{address}</Address>
            </div>
            {isOpen ? <Open>Jetzt geöffnet</Open> : null}
          </Content>
          <a target='_blank' rel='noreferrer' href={getRoute(address)}>
            <Route src={isActive ? ASSETS_PATH + "route_active.png" : ASSETS_PATH + "route.png"} />
          </a>
        </Flex>
        <ResultRowDetails onOpen={loadMore} data={details} />
      </Window>
    </Root>
  );
};

export default ResultRow;
