import React from "react";
import { InfoWindow, Marker } from "@react-google-maps/api";
import ResultRow from "./result-row";
import { ASSETS_PATH } from "../config";

const fallback = {
  lat: 53.14118,
  lng: 8.21467,
};

interface MapMarkerProps {
  data: google.maps.places.PlaceResult;
  isActive: boolean;
  onClick: (data: google.maps.places.PlaceResult) => void;
  map?: google.maps.Map;
}

const MapMarker = ({ data, isActive, onClick, map }: MapMarkerProps) => {
  return (
    <Marker
      clickable
      icon={isActive ? ASSETS_PATH + "marker_active.png" : ASSETS_PATH + "marker.png"}
      key={data.place_id}
      position={data.geometry?.location || fallback}
      animation={google.maps.Animation.DROP}
      onMouseDown={() => onClick(data)}
    >
      {isActive ? (
        <InfoWindow position={data.geometry?.location || fallback}>
          <ResultRow
            fullWidth
            isOpen={data.opening_hours?.open_now || false}
            address={data.vicinity || ""}
            key={data.place_id}
            title={data.name}
            isActive
            map={map}
            placeId={data.place_id || ""}
          />
        </InfoWindow>
      ) : null}
    </Marker>
  );
};
export default MapMarker;
